import { send } from "../index";
// 预警列表 资产管理员
export function getWarnListByType(data, opts = {}) {
  return send({
    url: "/admin/backlog/adminExpireWarnList.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 预警列表 财政管理员
export function getWarnListByUnit(data, opts = {}) {
  return send({
    url: "/admin/backlog/fiscalExpireWarnList.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 盘亏预警列表 资产管理员
export function getAdminLossWarnList(data, opts = {}) {
  return send({
    url: "/admin/backlog/adminLossWarnList.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 盘亏预警列表 财政管理员
export function getFiscalLossWarnList(data, opts = {}) {
  return send({
    url: "/admin/backlog/fiscalLossWarnList.do",
    method: "POST",
    data,
    ...opts,
  });
}
// 盘点结果
export function getWarnInventoryResult(data, opts = {}) {
  return send({
    url: "/admin/backlog/inventoryResult.do",
    method: "POST",
    data,
    ...opts,
  });
}